export const STATUSES = [
  'Inactive',
  'Active',
  'Pending',
  'In Progress'
];

export const STATUSES_SELECTION = [
  {
    'text': 'Inactive',
    'value': 0
  },
  {
    'text': 'Active',
    'value': 1
  },
  {
    'text': 'Pending',
    'value': 2
  }
];

export const STATUSES_VALUES = [
  0, 1, 2, 3
];

export const STATUSES_ENUM = {
  'INACTIVE': 0,
  'ACTIVE': 1,
  'PENDING': 2,
};




export const PROGRESS_STATUSES = [
  'Failed',
  'Done',
  'Pending',
  'In Progress',
];

export const PROGRESS_SELECTION = [
  {
    'text': 'Failed',
    'value': 0
  },
  {
    'text': 'Done',
    'value': 1
  },
  {
    'text': 'Pending',
    'value': 2
  },
  {
    'text': 'In Progress',
    'value': 3
  }
];




export const ORDER_STATUSES = [
  'Canceled',
  'Done',
  'In Progress',
];

export const ORDER_VALUES = [
  0, 1, 3
];

export const ORDER_SELECTION = [
  {
    'text': 'Canceled',
    'value': 0
  },
  {
    'text': 'Done',
    'value': 1
  },
  {
    'text': 'In Progress',
    'value': 3
  },
];


export const COUPON_TYPES_VALUES = [
  1, 2
];

export const COUPON_TYPES = [
  {
    text: '%',
    value: 1
  },
  {
    text: '₪',
    value: 2
  },
];